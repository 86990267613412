import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { addMessage, changeContext, messageHeapAdd } from "redux/actions";
import { ChatState, Option } from "redux/types";
import TextMessage from "./TextMessage";
import { generic_message } from "helpers/test_messages";
import switchMessage from "helpers/switch_message";
import usePostApi from "hooks/usePostAPI";
import { v4 as uuidv4 } from "uuid";
import { generateUnixDate } from "helpers/date";
import { useEffect } from "react";

const OptionList = styled.div`
  padding: 0.5rem;
  border-radius: 8px;
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  margin: 0.2rem 0;
  width: 28rem;
`;

const OptionItem = styled.button`
  padding: 0.4rem 0.4rem;
  margin: 0.2rem 0.2rem;
  max-height: 5rem;
  min-height: 50px;
  width: 13rem;
  border: 1px solid ${props => props.theme.color.gray4};
  border-radius: 10px;
  /* box-shadow: ${props => props.theme.color.white3} 0 3px 1px; */
  cursor: pointer;
  outline: none;
  background: ${props => props.theme.assistant.color_options_bg};

  &:hover {
    background: ${props => props.theme.color.white1};
    color: ${props => props.theme.color.white3};
  }

  &:active {
    transform: translateY(2px);
  }

  & p {
    color: ${props => props.theme.assistant.color_message};
    font-weight: 600;
  }
`;

interface Props {
  options: Array<Option>;
  type?: "suggestion" | "option";
}

const OptionMessage = ({ options, type = "option" }: Props): JSX.Element => {
  const { execute, status, value, error } = usePostApi();
  const dispatch = useDispatch();
  const context = useSelector((state: ChatState) => state.context);

  const handleClick = (text: string) => {
    if (text !== "") {
      dispatch(
        addMessage({
          ...generic_message,
          id: uuidv4(),
          sender: "Usuário",
          text,
          timestamp: generateUnixDate(),
        })
      );

      execute(text, context);
    }
  };

  useEffect(() => {
    switch (status) {
      case "success":
        if (value != null) {
          dispatch(changeContext(value.context));
          dispatch(
            messageHeapAdd(
              value.output.generic
                .map(message => switchMessage(message))
                .reverse()
            )
          );
        }
        break;
      case "error":
        console.log("Ocorreu um erro: ", error);
        break;
      case "pending":
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <OptionList>
      {options.map((option, index) => (
        <OptionItem
          key={index}
          onClick={() => {
            if (type === "option") {
              handleClick(option.value.input.text);
            } else {
              handleClick(option.label);
            }
          }}
        >
          <TextMessage text={option.label} />
        </OptionItem>
      ))}
    </OptionList>
  );
};

export default OptionMessage;
