import { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { addMessage, changeContext, messageHeapAdd } from "redux/actions";
import { generic_message } from "helpers/test_messages";
import { v4 as uuidv4 } from "uuid";
import switchMessage from "helpers/switch_message";
import SelectedImagesBox from "./subComponents/SelectedImagesBox";
import usePostApi from "hooks/usePostAPI";
import { ChatState } from "redux/types";
import { generateUnixDate } from "helpers/date";
import { ReactComponent as SubmitIcon } from "assets/submit-icon.svg";
import { ReactComponent as AttachIcon } from "assets/attach-icon.svg";
import { ReactComponent as LogoDigitaly } from "assets/logo-digitaly.svg";
import axios from "axios";
import { useRelatoriesService } from "hooks/useRelatoriesService";
// import { devices } from "helpers/devices";

const Form = styled.form`
  height: 100%;
  width: 100%;
  align-self: end;
  max-height: 60px;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
  /* border-radius: 0 0 10px 10px; */
  background: ${props => props.theme.assistant.color_chat_bg};
  border: 1px solid ${props => props.theme.color.white3};
`;

const Input = styled.input`
  width: 100%;
  margin-right: 0.5rem;
  padding: 0.3rem;
  background: ${props => props.theme.assistant.color_input_bg};
  border-radius: 8px;
  color: ${props => props.theme.color.gray3};
  font-size: 1rem;
  border: none;
  outline: none;

  &::placeholder {
    color: ${props => props.theme.color.gray4};
  }
`;

const Button = styled.button`
  width: 45px;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 50%;
  background: ${props => props.theme.assistant.color_button_bg};
  cursor: pointer;
  transform: scale(0.95);
  transition: all 0.3s ease;
  /* box-shadow: 1px 2px 4px ${props => props.theme.color.transparent_clear}; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  margin: 0 0.3rem;
  /* will-change: transform; */

  &:hover {
    transform: scale(1);
    /* background: ${props => props.theme.color.primary_main}; */
  }
`;

const FlexSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.1rem;
  border-left: 1px solid ${props => props.theme.color.white3};
`;

const FolderInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
`;

const FooterDigitaly = styled.div`
  height: 100%;
  width: 100%;
  max-height: 20px;
  padding: 0.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0 0 10px 10px;
  background: ${props => props.theme.assistant.color_footer_digitaly};
  /* border: 1px solid ${props => props.theme.color.white3}; */

  & svg {
    margin: 0 0.2rem;
    cursor: pointer;
  }

  & p {
    font-size: 0.5rem;
    color: ${props => props.theme.color.white1};
    margin: 0 0.3rem;
    cursor: pointer;
  }

  & a {
    display: none;
  }
`;

const SendMessageBox = (): JSX.Element => {
  const { execute, status, value, error } = usePostApi();
  const { generateSaldoDIs } = useRelatoriesService();

  const [messageText, setMessageText] = useState<string>("");
  const [files, setFiles] = useState<FileList | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabledSendButton, setDisabledSend] = useState(false);
  const [disabledMessage, setDisabledMessage] = useState("");
  const context = useSelector((state: ChatState) => state.context);
  const dispatch = useDispatch();

  useEffect(() => {
    if (context?.arquivos?.enabled) {
      setDisabledSend(() => true);
    } else {
      setDisabledSend(() => false);
    }
  }, [context?.arquivos?.enabled]);

  const closeImageSelection = () => {
    setFiles(null);
  };

  const handleSubmit = (
    messageTextParam: string = messageText,
    nextContext: any = {}
  ): void => {
    const newContext = {
      ...context,
      ...nextContext,
    };

    if (files == null) {
      if (messageTextParam !== "") {
        dispatch(
          addMessage({
            ...generic_message,
            id: uuidv4(),
            sender: "Usuário",
            text: messageTextParam,
            timestamp: generateUnixDate(),
          })
        );

        if (!isDisabled) {
          execute(messageTextParam, newContext);
        }
        setMessageText("");
      }
    } else {
      dispatch(
        addMessage({
          ...generic_message,
          id: uuidv4(),
          response_type: "image",
          sender: "Usuário",
          title: messageTextParam,
          description: "Description",
          timestamp: generateUnixDate(),
          images: [...files].map(file => URL.createObjectURL(file)),
        })
      );
      setMessageText("");
      closeImageSelection();
    }
  };

  useEffect(() => {
    if (context === undefined) {
      setIsDisabled(true);
      execute("", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (status) {
      case "success":
        if (value != null) {
          dispatch(changeContext(value!.context));
          dispatch(
            messageHeapAdd(
              value.output.generic
                .map(message => switchMessage(message))
                .reverse()
            )
          );
        }
        setIsDisabled(false);
        break;
      case "error":
        console.log("Ocorreu um erro: ", error);
        break;
      case "pending":
        setIsDisabled(true);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (context?.relatories?.DIs) {
      const dis = context.relatories.DIs;
      setIsDisabled(() => true);
      setDisabledMessage(() => "Processando DIs...");
      generateSaldoDIs(dis).then(isSuccess => {
        if (isSuccess) {
          handleSubmit("Recebi os arquivos!", {
            relatories: {},
          });
          setDisabledMessage(() => "Gerando Arquivo...");
          setTimeout(() => {
            setDisabledMessage(() => "");
            setIsDisabled(() => false);
          }, 1000);
        } else {
          setDisabledMessage(() => "Consulta Vazia...");
          setTimeout(() => {
            setDisabledMessage(() => "");
            setIsDisabled(() => false);
          }, 1000);

          handleSubmit("Minha consulta retornou vazia.", {
            relatories: {},
          });
        }
      });
    }
  }, [context?.relatories?.DIs]);

  return (
    <>
      <SelectedImagesBox
        images={files}
        closeImageSelection={closeImageSelection}
      />
      <Form
        id="SendMessageForm"
        style={{
          position: "relative",
          zIndex: 10,
        }}
        onSubmit={event => {
          event.preventDefault();

          if (!isDisabled) {
            handleSubmit();
          }
        }}
      >
        {isDisabled && (
          <div
            style={{
              zIndex: 20,
              width: "100%",
              height: "100%",
              position: "absolute",
              left: 0,
              top: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              userSelect: "none",
              background: "rgba(255,255,255,.7)",
            }}
          >
            {disabledMessage}
          </div>
        )}
        <Input
          type="text"
          maxLength={2000}
          autoFocus
          name="messageText"
          value={messageText}
          onChange={({ target }) => {
            setMessageText(target.value);
          }}
          placeholder="Digite sua mensagem..."
        />
        <FlexSpan>
          {/* <FolderButton
            as="label"
            htmlFor="folderInput"
            title="Adicionar arquivo."
            onClick={() => closeImageSelection()}
          /> */}
          {context?.arquivos?.enabled && (
            <>
              <FolderInput
                id="folderInput"
                placeholder="Escolha um arquivo"
                form="SendMessageForm"
                type="file"
                accept="image/*"
                multiple
                onChange={({ target }) => {
                  setFiles(null);

                  setIsDisabled(() => true);
                  setDisabledMessage(() => "Fazendo upload do arquivo...");

                  const [file] = Array.from(target.files || []);

                  const url =
                    context?.arquivos?.url ||
                    "http://localhost:3030/api/rpa/file-upload";
                  const method = context?.arquivos?.method || "POST";
                  const headers = context?.arquivos?.headers || {};

                  const body: any = context?.arquivos?.body || {
                    bucketId: "15754",
                    "query[path]": `DI-Exchange-${Date.now()}-file.xlsx`,
                  };

                  const responseMsg =
                    context?.arquivos?.nextMessage || "Enviando arquivos...";
                  const nextBody = { ...body, file: file };

                  const formData = new FormData();
                  for (const keyBody in nextBody) {
                    const value = nextBody[keyBody];
                    formData.append(keyBody, value);
                  }

                  axios({
                    method,
                    url,
                    headers,
                    data: formData,
                  })
                    .then(res => {
                      closeImageSelection();
                      handleSubmit(responseMsg, {
                        arquivos: {
                          ...context?.arquivos,
                          enabled: false,
                          response: res.data,
                        },
                      });
                    })
                    .finally(() => {
                      setIsDisabled(() => false);
                      setDisabledMessage(() => "");
                    });
                }}
              />
              <Button
                title="Adicionar arquivo."
                type="button"
                onClick={() => {
                  document.getElementById("folderInput")!.click();
                  closeImageSelection();
                }}
              >
                <AttachIcon />
              </Button>
            </>
          )}
          <Button title="Enviar!" type="submit" disabled={isDisabledSendButton}>
            <SubmitIcon />
          </Button>
        </FlexSpan>
      </Form>
      <FooterDigitaly>
        <a
          id="linkedDigitaly"
          href="https://digitaly.tech"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>Digitaly</p>
        </a>
        <LogoDigitaly
          onClick={() => {
            document.getElementById("linkedDigitaly")!.click();
          }}
        />
        <p
          onClick={() => {
            document.getElementById("linkedDigitaly")!.click();
          }}
        >
          © 2021 | Criado e desenvolvido por DIGITALY
        </p>
      </FooterDigitaly>
    </>
  );
};

export default SendMessageBox;
