import { useDispatch } from "react-redux";
import styled from "styled-components";
import { changeContext, clearMessages } from "redux/actions";

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.color.transparent_opaque};
  z-index: 100;
  margin-top: 1rem; //Editar
`;

const Text = styled.h4`
  color: ${props => props.theme.color.white4};
  text-align: center;
  max-width: 80%;
`;

const Button = styled.button<{ danger?: boolean }>`
  margin: 0 0.3rem;
  padding: 0 0.5rem;
  height: 42px;
  color: ${props =>
    props.danger ? props.theme.color.white4 : props.theme.color.white1};
  border: none;
  background: ${props =>
    props.danger ? props.theme.color.deny : props.theme.color.gray3};
  border-radius: 8px;
  cursor: pointer;
  transform: scale(0.95);
  transition: transform 0.3s ease;
  will-change: transform;

  &:hover {
    transform: scale(1);
  }
`;

const ButtonGroup = styled.div`
  width: 15%;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  & button {
    flex: 1 1 50%;
  }
`;

interface Props {
  showModal: boolean;
  toggleSetVisible: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    askBeforeClosing: boolean
  ) => void;
  toggleModal: () => void;
}

const ClosingModal = ({
  showModal,
  toggleSetVisible,
  toggleModal,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const eraseContext = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    dispatch(changeContext(undefined));
    dispatch(clearMessages());

    toggleModal();
    toggleSetVisible(event, false);
  };

  if (showModal) {
    return (
      <Container>
        <Text>Deseja fechar e apagar esta conversa?</Text>
        <ButtonGroup>
          <Button onClick={() => toggleModal()}>Não</Button>
          <Button danger onClick={event => eraseContext(event)}>
            Sim
          </Button>
        </ButtonGroup>
      </Container>
    );
  } else {
    return null;
  }
};

export default ClosingModal;
