import styled from "styled-components";
import AvatarIcon from "assets/avatar.png";
import { ReactComponent as ExpandIcon } from "assets/expand.svg";
import { ReactComponent as CloseIcon } from "assets/close-window.svg";
import { ReactComponent as MinimizeIcon } from "assets/window-minimize.svg";
// import { devices } from 'helpers/devices';

const StyledHeader = styled.header`
  width: auto;
  height: 100%;
  max-height: 50px;
  position: relative;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.assistant.color_header_bg};
  border-radius: 10px 10px 0 0;
  /* box-shadow: 1px 2px 4px ${props => props.theme.color.transparent_clear}; */
  z-index: 2;
`;

const IconContainer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleName = styled.h2`
  color: ${props => props.theme.assistant.color_header_title};
  font-size: 1rem;
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 18ch;

  @media (max-width: 360px) {
    font-size: 0.9rem;
  }
`;

const Button = styled.button<{ isRotate?: boolean; isDisplay?: boolean }>`
  width: 35px;
  height: 35px;
  color: ${props => props.theme.color.white1};
  border: none;
  background: transparent;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.3s ease;
  will-change: transform;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem;
  outline: none;

  &:hover {
    transform: scale(1.1);
  }

  & svg {
    transform: ${props =>
      props.isRotate ? "rotateZ(0.5turn)" : "rotateZ(0turn)"};
    width: 18px;
    height: 18px;
  }

  @media (max-width: 400px) {
    display: ${props => (props.isDisplay ? "none" : "flex")};
  }
`;

const Icon = styled.img`
  width: 60px;
  height: auto;
  /* max-height: 100%; */
  margin-right: 0.4rem;
  border-radius: 50%;

  @media (max-width: 360px) {
    width: 40px;
  }

  @media (max-width: 350px) {
    display: none;
  }
`;

const ButtonContainer = styled.span`
  display: flex;
  justify-content: space-around;
  align-items: center;

  & button {
    margin: 0 0.1rem;
  }
`;

interface Props {
  text: string;
  toggleSetVisible: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    askBeforeClosing: boolean
  ) => void;
  toggleExpand: () => void;
  isExpanded: boolean;
}

const Header = ({
  text,
  toggleExpand,
  toggleSetVisible,
  isExpanded,
}: Props): JSX.Element => (
  <StyledHeader id="Header">
    <IconContainer>
      <Icon src={AvatarIcon} alt="Icone do Chat" />
      <TitleName>{text}</TitleName>
    </IconContainer>
    <ButtonContainer>
      <Button
        isRotate={isExpanded}
        isDisplay
        title="Expandir chat"
        onClick={() => toggleExpand()}
      >
        <ExpandIcon />
      </Button>
      <Button
        title="Minimizar chat"
        onClick={event => toggleSetVisible(event, false)}
      >
        <MinimizeIcon />
      </Button>
      <Button
        title="Fechar chat"
        onClick={event => toggleSetVisible(event, true)}
      >
        <CloseIcon />
      </Button>
    </ButtonContainer>
  </StyledHeader>
);

export default Header;
