import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.color.transparent_opaque};
  z-index: 25;
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  width: 100%;
  max-width: 80%;
  height: auto;
  max-height: 85%;
`;

const ButtonGroup = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  margin: 0 0.3rem;
  padding: 0.5rem;
  height: 42px;
  width: 42px;
  color: ${props => props.theme.color.white1};
  border: none;
  background: ${props => props.theme.color.gray3};
  border-radius: 50%;
  cursor: pointer;
  transform: scale(0.95);
  transition: transform 0.3s ease;
  will-change: transform;

  &:hover {
    transform: scale(1);
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  background: ${props => props.theme.color.deny};
`;

interface Props {
  images: string[];
  index: number;
  showModal: boolean;
  setIndex: (index: number) => void;
  setModal: () => void;
}

const ImageModal = ({
  images,
  index,
  showModal,
  setIndex,
  setModal,
}: Props): JSX.Element | null => {
  if (showModal) {
    return (
      <Container>
        <ImageContainer>
          <CloseButton onClick={() => setModal()}>X</CloseButton>
          <Image src={images[index]} alt="" />
          {images.length > 1 ? (
            <ButtonGroup>
              <Button onClick={() => setIndex(index - 1)}>&#x276E;</Button>
              <Button onClick={() => setIndex(index + 1)}>&#x276f;</Button>
            </ButtonGroup>
          ) : null}
        </ImageContainer>
      </Container>
    );
  } else {
    return null;
  }
};

export default ImageModal;
