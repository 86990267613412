import { useSelector } from "react-redux";
import { ChatState } from "redux/types";
import styled, { keyframes } from "styled-components";

const slideUp = keyframes`
    0% { max-height: 0 }
    100% { max-height: 56.4px }
    `;

const HorizontalList = styled.div`
  padding: 0.2rem 0.4rem;
  display: flex;
  align-items: center;
  overflow-x: auto;
  background: ${props => props.theme.color.gray2};
  animation: ${slideUp} 400ms 1 ease-out;
`;

const ImageList = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  & p {
    color: ${props => props.theme.color.white1};
    flex-shrink: 0;
  }
`;

const Thumbnail = styled.img`
  width: 50px;
  height: 50px;
  margin: 0 0.2rem;
`;

const CloseButton = styled.button`
  margin-left: 1.3rem;
  padding: 4px;
  height: 20px;
  color: ${props => props.theme.color.white1};
  border: none;
  background: ${props => props.theme.color.deny};
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  font-size: 10px;
`;

const AnalyzeButton = styled.button`
  padding: 4px;
  height: 20px;
  color: ${props => props.theme.color.white1};
  border: none;
  background: ${props => props.theme.color.primary_main};
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  font-size: 10px;
`

interface Props {
  images: FileList | null;
  closeImageSelection: Function;
  onSubmit?: (data: any) => void
}

const SelectedImagesBox = ({
  images,
  closeImageSelection,
  onSubmit,
}: Props): JSX.Element | null => {
  const context = useSelector((state: ChatState) => state.context);

  if (images == null) {
    return null;
  } else {
    return (
      <HorizontalList>
        <ImageList id="HorizontalImageList">
          {[...images].map((image, index) => (
            <Thumbnail
              width="50px"
              height="50px"
              key={index}
              src={URL.createObjectURL(image)}
            />
          ))}
          <p>
            {images.length} selecionado{images.length > 1 ? "s" : ""}
          </p>
        </ImageList>
        {/* <AnalyzeButton
          onClick={() => {
            console.log('On submit files', )
            // call('TEXT_DETECTION', images[0]).then((data) => {
            //   if (data) {
            //     closeImageSelection()
            //     onSubmit?.(data)
            //   }
            // })
          }}
        >
          Analizar Documento
        </AnalyzeButton> */}
        <CloseButton onClick={() => closeImageSelection()}>
          Cancelar
        </CloseButton>
      </HorizontalList>
    );
  }
};

export default SelectedImagesBox;

