import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {
  createGlobalStyle,
  ThemeProvider,
  DefaultTheme,
} from "styled-components";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { chatReducer } from "redux/chatReducer";

const globalStore = createStore(
  chatReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

const GlobalStyle = createGlobalStyle`
    :root {
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: #ddd;
    }
    body {
        font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
`;

const theme: DefaultTheme = {
  color: {
    primary_main: "#279a10",
    primary_light: "#52b424",
    primary_dark: "#4b7a42",
    secondary_main: "#007db5",
    secondary_light: "#d2f6ff",
    secondary_dark: "#599aaa",
    button_normal: "#6fa778",
    button_deny: "#ff6e6e",
    button_normal_hover: "#81b88a",
    button_deny_hover: "#f88484",
    text1: "#181917",
    text2: "#FFF",
    text3: "#747774",
    approval: "#87c443",
    warn: "#ffdc2a",
    deny: "#d12121",
    white1: "#FFF",
    white2: "#f2f2f2",
    white3: "#ddd",
    white4: "#dce3ed",
    white5: "#d9e3d7",
    gray1: "#111",
    gray2: "#181917",
    gray3: "#5f5f5f",
    gray4: "#979797",
    transparent_opaque: "rgba(24, 24, 24, 0.8)",
    transparent_clear: "rgba(24, 24, 24, 0.5)",
  },
  font: {
    huge: "4rem",
    large: "3rem",
    big: "2.2rem",
    medium: "1.8rem",
    normal: "1rem",
    small: ".8rem",
    very_small: ".4rem",
  },
  margins: {
    from_sides: "4rem",
    from_sides_mobile: "1.5rem",
  },
  assistant: {
    color_header_bg: "#053d6f",
    color_header_title: "#FFF",
    color_body_bg: "#FFFFFF",
    color_chat_bg: "#FFFFFF",
    color_input_bg: "#FFFFFF",
    color_button_bg: "#ffb121",
    color_options_bg: "#d7e7f5",
    color_message: "#111",
    color_message_bg: "#ddd",
    color_message_bot_bg: "#F4F8FF",
    color_footer_digitaly: "#053d6f",
  },
};

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <Provider store={globalStore}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
