import styled from "styled-components";
import { DisplayMessageText } from "redux/types";

const VideoContainer = styled.div`
  padding: 0.5rem;
  border-radius: 8px;
  background: ${props => props.theme.color.gray2};
  margin: 0.2rem 0;

  & iframe {
    width: 100%;
    height: auto;
    border: none;
  }
`;

interface Props {
  message: DisplayMessageText;
}

const YoutubeEmbed = ({ message }: Props): JSX.Element | null => {
  const videoRegex = /youtube\.com\/watch\?v=([a-zA-Z0-9_-]*)&?.*/;

  const hasVideo = (): boolean => videoRegex.test(message.text);

  if (hasVideo()) {
    return (
      <VideoContainer>
        <iframe
          width="250"
          height="200"
          src={`https://www.youtube.com/embed/${
            videoRegex.exec(message.text)![1]
          }`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoContainer>
    );
  } else {
    return null;
  }
};

export default YoutubeEmbed;
