import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMessage, messageHeapPop } from "redux/actions";
import { ChatState } from "redux/types";
import styled, { keyframes } from "styled-components";
import ChatContainer from "./components/ChatContainer/ChatContainer";
import ClosingModal from "./components/ClosingModal/ClosingModal";
import AvatarIcon from "assets/avatar.png";
import Lottie from "react-lottie";
import animationData from "animation/test.json";

const Bounce = keyframes`
    0% {
        transform: scale(1.2) translateY(0);
    }
    25% {
        transform: scale(1.2) translateY(-10px);
    }
    50% {
        transform: scale(1.2) translateY(0);
    }
    75% {
        transform: scale(1.2) translateY(-10px);
    }
    100% {
        transform: scale(1.2) translateY(0);
    }
    `;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  bottom: 0;
  right: 0;
`;

const Button = styled.button<{ display: boolean }>`
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 60px;
  height: 60px;
  color: ${props => props.theme.color.primary_main};
  border: none;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  transform: scale(0.95);
  transition: transform 0.3s ease;
  will-change: transform;
  background-image: url("${AvatarIcon}");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  animation: ${Bounce} 1 3s ease;
  display: ${props => (props.display ? "flex" : "none")};

  &:hover {
    transform: scale(1.1);
  }
`;

const App = (): JSX.Element => {
  const messageHeap = useSelector((state: ChatState) => state.messageHeap);
  // Animação ligada colocar "True" || Animação desligada colocar "false"
  const [animation, setAnimation] = useState<boolean>(false);
  // Animação ligada colocar "false" || Animação desligada colocar "true"
  const [animationEnd, setAnimationEnd] = useState<boolean>(true);
  // Animação ligada colocar "True" || Animação desligada colocar "false"
  const [chatIsVisible, setChatIsVisible] = useState<boolean>(true);
  const [showClosingModal, setShowClosingModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const toggleSetVisible = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    askBeforeClosing: boolean
  ) => {
    event.preventDefault();

    if (askBeforeClosing) {
      setShowClosingModal(true);
    } else {
      setChatIsVisible(!chatIsVisible);
    }
  };

  const toggleModal = (): void => {
    setShowClosingModal(!showClosingModal);
  };

  const handleAnimationEnd = () => {
    setAnimation(!animation);
    setAnimationEnd(!animationEnd);
    setChatIsVisible(false);
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (messageHeap.length > 0) {
      dispatch(addMessage(messageHeap[messageHeap.length - 1]));

      setTimeout(() => {
        dispatch(messageHeapPop());
      }, 1200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageHeap]);

  // Envia uma mensagem para o site que chamar esse como iframe.
  useEffect(() => {
    const message = JSON.stringify({
      channel: "watson",
      iframeState: chatIsVisible ? "OPEN" : "CLOSED",
    });

    window.parent.postMessage(message, "*");
  }, [chatIsVisible]);
  return (
    <Container>
      {animation ? (
        <Lottie
          options={defaultOptions}
          eventListeners={[
            {
              eventName: "complete",
              callback: () => handleAnimationEnd(),
            },
          ]}
        />
      ) : null}
      {chatIsVisible ? (
        <>
          <ChatContainer toggleSetVisible={toggleSetVisible} />
          <ClosingModal
            showModal={showClosingModal}
            toggleModal={toggleModal}
            toggleSetVisible={toggleSetVisible}
          />
        </>
      ) : (
        <Button
          display={animationEnd}
          role="button"
          onClick={event => toggleSetVisible(event, false)}
        ></Button>
      )}
    </Container>
  );
};

export default App;
