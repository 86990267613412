import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CHAT_API
    ? process.env.REACT_APP_CHAT_API
    : "https://dascam-watson-api.digitaly.tech",
});

export function useRelatoriesService() {
  const generateSaldoDIs = (dis: string[]) => {
    return axiosInstance
      .post(
        `/api/relatory/saldo`,
        { DIs: dis },
        { responseType: "arraybuffer" }
      )
      .then(res => res.data)
      .then(bufferString => {
        console.log(bufferString);
        const a = document.createElement("a");
        const blob = new Blob([bufferString], {
          type: "application/octet-stream",
        });
        const blobUrl = URL.createObjectURL(blob);
        a.href = blobUrl;
        a.target = "_blank";
        a.download = `Saldos de DIs.xlsx`;

        a.click();

        URL.revokeObjectURL(blobUrl);

        return true;
      })
      .catch(err => {
        const response = err.response;
        if (response) {
          console.error("RESPONSE!!!!!");
          console.log(response);
        }

        return false;
      });
  };

  return {
    generateSaldoDIs,
  };
}
