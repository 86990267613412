import axios from "axios";
import { useState } from "react";
import { WatsonResponse, Context } from "redux/types";

// const axiosInstance = axios.create({
//   baseURL: process.env.REACT_APP_CHAT_API,
// });

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CHAT_API
    ? process.env.REACT_APP_CHAT_API
    : "https://dascam-watson-api.digitaly.tech",
});

/**
 * Hook que executa pedidos à API do chat, atualiza seu state interno e também disponibiliza seus valores ao App.
 * @returns {{Function, string, ApiResponse, Error}} Uma função para atualizar o state, o status do state,
 * o valor do state em si, e o erro retornado (se houver).
 */
export default function usePostApi() {
  const [status, setStatus] = useState<string>("idle");
  const [value, setValue] = useState<WatsonResponse | null>(null);
  const [error, setError] = useState<any>(null);

  /**
   * Executa um POST request na API do chat e atualiza o state interno da hook.
   * @async
   * @param message - Uma mensagem válida.
   * @param context - Um contexto de conversa. Caso o contexto seja indefinido, somente a mensagem é enviada.
   * @returns {Promise} Uma promise.
   */
  const execute = (
    message: string,
    context: Context | undefined
  ): Promise<void> => {
    setStatus("pending");
    setValue(null);
    setError(null);
    let body: { message: string; context?: Context };

    if (context !== undefined) {
      body = {
        message,
        context,
      };
    } else {
      body = {
        message,
      };
    }

    return axiosInstance
      .post("/api/conversation/message", body)
      .then(response => {
        setValue(response.data);
        setStatus("success");
      })
      .catch(error => {
        setError(error);
        setStatus("error");
      });
  };

  return { execute, status, value, error };
}
