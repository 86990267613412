import {
  AllowedDisplayMessageTypes,
  AllowedResponseMessageTypes,
} from "redux/types";
import { generic_message } from "./test_messages";
import { v4 as uuidv4 } from "uuid";
import { generateUnixDate } from "./date";

/**
 * Retorna uma mensagem que pode ser lida pelo App, baseada em um tipo de mensagem retornada externamente.
 * @param {AllowedMessageTypes} message - Uma mensagem válida, com tipo, retornada por uma API externa.
 * @returns {Message} Message - Uma mensagem válida, que pode ser exibida pelo App.
 */
export default function switchMessage(
  message: AllowedResponseMessageTypes
): AllowedDisplayMessageTypes {
  switch (message.response_type) {
    case "text":
      return {
        ...generic_message,
        id: uuidv4(),
        text: message.text,
        timestamp: generateUnixDate(),
      };
    case "option":
      return {
        ...generic_message,
        id: uuidv4(),
        response_type: message.response_type,
        description: message.description,
        title: message.title,
        options: message.options,
        timestamp: generateUnixDate(),
      };
    case "suggestion":
      return {
        ...generic_message,
        id: uuidv4(),
        title: message.title,
        response_type: message.response_type,
        suggestions: message.suggestions,
        timestamp: generateUnixDate(),
      };
    case "image":
      return {
        ...generic_message,
        id: uuidv4(),
        response_type: message.response_type,
        description: message.description,
        title: message.title,
        images: message.source,
        timestamp: generateUnixDate(),
      };
    case "youtube":
    case "vimeo":
      return {
        ...generic_message,
        id: uuidv4(),
        response_type: message.response_type,
        source: message.source,
        timestamp: generateUnixDate(),
      };
    default:
      console.error(
        `Tipo de mensagem ${message["response_type"]} não possui representação no sistema!`
      );
      return {
        ...generic_message,
        id: uuidv4(),
        response_type: "text",
        text: "",
        timestamp: generateUnixDate(),
      };
  }
}
