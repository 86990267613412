import {
  Context,
  ChatActionTypes,
  AllowedDisplayMessageTypes,
} from "redux/types";

export const MESSAGE_ADD = "MESSAGE/ADD";
export const MESSAGE_CLEAR = "MESSAGE/CLEAR";

export const MESSAGE_HEAP_ADD = "MESSAGE_HEAP/ADD";
export const MESSAGE_HEAP_POP = "MESSAGE_HEAP/POP";
export const MESSAGE_HEAP_CLEAR = "MESSAGE_HEAP/CLEAR";

export const CONTEXT_CHANGE = "CONTEXT/CHANGE";

/**
 * Gerador de Action para adicionar uma mensagem ao redux.
 * @param {Message} newMessage - Uma mensagem válida.
 * @returns {ChatActionTypes} Uma action do tipo MessageSendAction que pode ser consumida pelo reducer.
 */
export function addMessage(
  newMessage: AllowedDisplayMessageTypes
): ChatActionTypes {
  return {
    type: MESSAGE_ADD,
    payload: newMessage,
  };
}

/**
 * Gerador de Action para apagar todas as mensagens.
 * @returns {ChatActionTypes} Uma action do tipo MessageSendAction que pode ser consumida pelo reducer.
 */
export function clearMessages(): ChatActionTypes {
  return {
    type: MESSAGE_CLEAR,
  };
}

export function messageHeapAdd(
  newMessages: Array<AllowedDisplayMessageTypes>
): ChatActionTypes {
  return {
    type: MESSAGE_HEAP_ADD,
    payload: newMessages,
  };
}

export function messageHeapPop(): ChatActionTypes {
  return {
    type: MESSAGE_HEAP_POP,
  };
}

export function messageHeapClear(): ChatActionTypes {
  return {
    type: MESSAGE_HEAP_CLEAR,
  };
}

/**
 * Gerador de action para guardar/alterar um contexto.
 * @param {Context} newContext - Um contexto de conversa.
 * @returns {ChatActionTypes} Uma action do tipo ContextChangeAction que ser consumida pelo reducer.
 */
export function changeContext(
  newContext: Context | undefined
): ChatActionTypes {
  return {
    type: CONTEXT_CHANGE,
    payload: newContext,
  };
}
