import styled from "styled-components";
import { DisplayMessageText } from "redux/types";

const VideoContainer = styled.div`
  padding: 0.5rem;
  border-radius: 8px;
  background: ${props => props.theme.color.gray2};
  margin: 0.2rem 0;

  & iframe {
    width: 100%;
    height: auto;
    border: none;
  }
`;

interface Props {
  message: DisplayMessageText;
}

const VimeoEmbed = ({ message }: Props): JSX.Element | null => {
  const videoRegex = /vimeo\.com\/(\d*)/;

  const hasVideo = (): boolean => videoRegex.test(message.text);

  if (hasVideo()) {
    return (
      <VideoContainer>
        <iframe
          width="250"
          height="200"
          src={`https://player.vimeo.com/video/${
            videoRegex.exec(message.text)![1]
          }?color=ffffff&portrait=0`}
          title="Vimeo video player"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </VideoContainer>
    );
  } else {
    return null;
  }
};

export default VimeoEmbed;
