const formatDateToDisplay = (dateObject: Date): string => {
  const hour = dateObject.getHours();
  const minutes = dateObject.getMinutes().toString();

  return `${hour}:${minutes.padStart(2, "0")}`;
};

const formatDateToUnix = (dateObject: Date): number => {
  return dateObject.getTime();
};

const generateUnixDate = (): number => {
  return formatDateToUnix(new Date());
};

export { formatDateToDisplay, formatDateToUnix, generateUnixDate };
