import { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import Header from "components/Header/Header";
import MessageList from "components/MessageList/MessageList";
import SendMessageBox from "components/SendMessageBox/SendMessageBox";
import { useSelector } from "react-redux";
import { ChatState } from "redux/types";
// import { devices } from 'helpers/devices';

const fadeToView = keyframes`
  from {
    transform: scale(0.9) translate(100%, 100%);
    opacity: 0;
  } to {
    opacity: 1;   
  }
`;

const StyledChatContainer = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: auto;
  height: 100vh;
  /* transition: opacity 150ms ease-out; */
  animation: ${fadeToView} 300ms 1 ease-out;
  padding-top: 1rem;
  background-color: transparent;
  border: none;
  outline: none;
`;

interface Props {
  toggleSetVisible: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    askBeforeClosing: boolean
  ) => void;
}

const ChatContainer = ({ toggleSetVisible }: Props): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const messages = useSelector((state: ChatState) => state.messages);
  const scrollToRef = useRef<HTMLDivElement>(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (scrollToRef.current != null) {
      // Delay para que imagens não atrapalharem o scroll
      setTimeout(() => {
        scrollToRef.current!.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 80);
    }
  }, [messages]);

  useEffect(() => {
    const message = JSON.stringify({
      channel: "watson",
      iframeState: isExpanded ? "EXPANDED" : "OPEN",
    });

    window.parent.postMessage(message, "*");
  }, [isExpanded]);

  return (
    <StyledChatContainer id="ChatContainer" isExpanded={isExpanded}>
      <Header
        toggleSetVisible={toggleSetVisible}
        text="Rafa"
        toggleExpand={toggleExpand}
        isExpanded={isExpanded}
      />
      <MessageList scrollToRef={scrollToRef} isExpanded={isExpanded} />
      <SendMessageBox />
    </StyledChatContainer>
  );
};

export default ChatContainer;
