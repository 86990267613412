import styled from "styled-components";
import { ChatState } from "redux/types";
import { useSelector } from "react-redux";
import GenericMessage from "./subComponents/GenericMessage";
import TextMessage from "./subComponents/TextMessage";
import OptionMessage from "./subComponents/OptionMessage";
import ImageMessage from "./subComponents/ImageMessage";
import YoutubeEmbed from "./subComponents/YoutubeEmbed";
import VimeoEmbed from "./subComponents/VimeoEmbed";
import { useState } from "react";
import ImageModal from "./subComponents/ImageModal";

const StyledMessageList = styled.ul<{ isExpanded: boolean }>`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 1rem;
  list-style: none;
  background: ${props => props.theme.assistant.color_body_bg};
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
  border-left: 1px solid ${props => props.theme.color.white3};
  border-right: 1px solid ${props => props.theme.color.white3};
`;

interface Props {
  scrollToRef: React.RefObject<HTMLDivElement> | null;
  isExpanded: boolean;
}

const MessageList = ({ scrollToRef, isExpanded }: Props): JSX.Element => {
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [modalImageIndex, setModalImageIndex] = useState<number>(0);
  const [modalImageList, setModalImageList] = useState<string[]>([]);
  const messages = useSelector((state: ChatState) => state.messages);

  const handleImageList = (imageList: string[]): void => {
    setModalImageList(imageList);
  };

  const handleShowModal = (): void => {
    setShowImageModal(!showImageModal);
  };

  const handleImageIndex = (index: number): void => {
    if (index < 0) {
      setModalImageIndex(modalImageList.length - 1);
      return;
    }

    if (index >= modalImageList.length) {
      setModalImageIndex(0);
      return;
    }

    setModalImageIndex(index);
  };

  return (
    <StyledMessageList id="MessageList" isExpanded={isExpanded}>
      {messages.map(message => {
        switch (message.response_type) {
          case "text":
            return (
              <GenericMessage key={message.id} message={message}>
                <TextMessage text={message.text} />
                <YoutubeEmbed message={message} />
                <VimeoEmbed message={message} />
              </GenericMessage>
            );
          case "option":
            return (
              <GenericMessage key={message.id} message={message}>
                <TextMessage text={message.title} />
                <OptionMessage options={message.options} />
              </GenericMessage>
            );
          case "suggestion":
            return (
              <GenericMessage key={message.id} message={message}>
                <TextMessage text={message.title} />
                <OptionMessage
                  options={message.suggestions}
                  type="suggestion"
                />
              </GenericMessage>
            );
          case "image":
            return (
              <GenericMessage key={message.id} message={message}>
                <ImageMessage
                  images={message.images}
                  setImageList={handleImageList}
                  setModal={handleShowModal}
                  setIndex={handleImageIndex}
                />
                <TextMessage text={message.title} />
              </GenericMessage>
            );
          default:
            return null;
        }
      })}
      <ImageModal
        images={modalImageList}
        index={modalImageIndex}
        showModal={showImageModal}
        setIndex={handleImageIndex}
        setModal={handleShowModal}
      />
      <div ref={scrollToRef}></div>
    </StyledMessageList>
  );
};

export default MessageList;
