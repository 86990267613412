import styled from "styled-components";

const ImageList = styled.div`
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  margin: 0.2rem 0;
`;

const ImageContainer = styled.div<{ isOneElement: boolean }>`
  flex-grow: 1;
  margin: ${props => (props.isOneElement ? ".1rem 0" : ".1rem .2rem")};
  cursor: zoom-in;
`;

const ImageContainerSmall = styled(ImageContainer)`
  width: 40%;
  height: auto;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;

  &:hover {
    opacity: 0.8;
  }
`;

interface Props {
  images: string[] | string;
  setImageList: (images: Array<string>) => void;
  setModal: () => void;
  setIndex: (index: number) => void;
}

const ImageMessage = ({
  images,
  setImageList,
  setModal,
  setIndex,
}: Props): JSX.Element => {
  if (typeof images === "string") {
    return (
      <ImageList>
        <ImageContainer
          isOneElement={true}
          onClick={() => {
            setImageList([images]);
            setIndex(0);
            setModal();
          }}
        >
          <Image height="100px" width="100px" src={images} />
        </ImageContainer>
      </ImageList>
    );
  } else if (images.length < 4) {
    return (
      <ImageList>
        {images.map((imageUrl, index) => (
          <ImageContainer
            key={index}
            isOneElement={images.length === 1}
            onClick={() => {
              setImageList(images);
              setIndex(index);
              setModal();
            }}
          >
            <Image height="100px" width="100px" src={imageUrl} />
          </ImageContainer>
        ))}
      </ImageList>
    );
  } else {
    return (
      <ImageList>
        {images.map((imageUrl, index) => (
          <ImageContainerSmall
            key={index}
            isOneElement={images.length === 1}
            onClick={() => {
              setImageList(images);
              setIndex(index);
              setModal();
            }}
          >
            <Image height="100px" width="100px" src={imageUrl} />
          </ImageContainerSmall>
        ))}
      </ImageList>
    );
  }
};

export default ImageMessage;
