import styled from "styled-components";

interface Props {
  text: string;
}

const Paragraph = styled.p`
  width: auto;
  word-wrap: anywhere;
  font-size: ${props => props.theme.font.small};

  & a {
    color: ${props => props.theme.color.white1};
  }
`;

const TextMessage = ({ text }: Props): JSX.Element | null => {
  const createMarkup = () => {
    return { __html: text };
  };

  if (text !== "") {
    return <Paragraph dangerouslySetInnerHTML={createMarkup()} />;
  } else {
    return null;
  }
};

export default TextMessage;
