import { ChatState, Context, DisplayMessageText } from "redux/types";
import { generateUnixDate } from "./date";

export const generic_message: DisplayMessageText = {
  id: "",
  response_type: "text",
  sender: "Rafa",
  text: "",
  timestamp: generateUnixDate(),
};

export const test_message: DisplayMessageText = {
  id: "0",
  response_type: "text",
  sender: "Rafa",
  text: "Test message",
  timestamp: 123,
};

export const test_context: Context = {
  conversation_id: "conversation_1",
  system: {
    initialized: true,
    dialog_stack: null,
    dialog_turn_counter: 1,
    dialog_request_counter: 1,
    _node_output_map: null,
    last_branch_node: "",
    branch_exited: false,
    branch_exited_reason: "",
  },
  metadata: {
    user_id: "user_1",
  },
};

export const test_state: ChatState = {
  messageHeap: [],
  messages: [
    {
      id: "3829xc23Pw",
      response_type: "text",
      sender: "Usuário",
      text: "Rafa?",
      timestamp: generateUnixDate(),
    },
    // {
    //     id: "98T9xc23Pw",
    //     response_type: "image",
    //     sender: "Watson",
    //     text: "Olá",
    //     timestamp: generateUnixDate(),
    //     images: [
    //         "https://media.tenor.com/images/c0451427b7854821eb02369a3823100b/tenor.gif"
    //     ]
    // },
    {
      id: "98T9xc23Pw",
      response_type: "text",
      sender: "Rafa",
      text: "Olá https://www.youtube.com/watch?v=z6IpRP7b4ss",
      timestamp: generateUnixDate(),
    },
    {
      id: "48T9xc29P8",
      response_type: "text",
      sender: "Usuário",
      text: "Preciso saber sobre a rotina X",
      timestamp: generateUnixDate(),
    },
    {
      id: "68T9xc75t9",
      response_type: "text",
      sender: "Rafa",
      text: "Trabalhando...",
      timestamp: generateUnixDate(),
    },
    {
      id: "68bixc25g4",
      sender: "Rafa",
      response_type: "text",
      text: "A rotina X é usada para Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      timestamp: generateUnixDate(),
    },
    {
      id: "68biyc32x4",
      sender: "Rafa",
      response_type: "option",
      title: "Opção",
      description: "Descrição",
      timestamp: generateUnixDate(),
      options: [
        {
          label: "Código",
          value: {
            input: {
              text: "Qual o código da rotina X?",
            },
          },
        },
        {
          label: "Opção filler",
          value: {
            input: {
              text: "Ao clicar na opção a mensagem é enviada automaticamente!",
            },
          },
        },
        {
          label: "Opção filler, com texto maior",
          value: {
            input: {
              text: "Ao clicar na opção a mensagem é enviada automaticamente!",
            },
          },
        },
        {
          label: "Exemplos",
          value: {
            input: {
              text: "Como eu posso usar a rotina X?",
            },
          },
        },
        {
          label:
            "Opção filler, com texto ainda maior, para testar a responsividade",
          value: {
            input: {
              text: "Ao clicar na opção a mensagem é enviada automaticamente!",
            },
          },
        },
      ],
    },
    {
      id: "jskduwinkbk",
      response_type: "image",
      sender: "Usuário",
      title: "Imagem",
      description: "Descrição",
      timestamp: generateUnixDate(),
      images: [
        "https://www.wallpapers13.com/wp-content/uploads/2016/04/Wonderful-autumn-landscape-mountain-lake-birch-and-pine-forest-with-yellow-and-green-leaves-rocky-mountains-with-snow-blue-with-white-clouds-Maroon-Bells-Elk-Mountains-Aspen-Colorado-915x515.jpg",
        "https://i0.wp.com/www.vortexmag.net/wp-content/uploads/2019/02/38094163914_25f4524191_b-e1542191835368.jpg?fit=1000%2C667&ssl=1",
        "https://www.advantour.com/img/kazakhstan/mountains/tien-shan.jpg",
      ],
    },
  ],
};

const watsonMessages = [
  "Olá, você enviou uma mensagem de teste! Estas mensagens começam com um ponto de exclamação. Ao detecta-las eu envio esta mensagem de volta.",
  "Olá, você enviou uma mensagem de teste!",
  "Olá, você enviou uma mensagem de teste! Estas mensagens começam com um ponto de exclamação.",
];

export function getWatsonMessage(): string {
  return watsonMessages[Math.floor(Math.random() * watsonMessages.length)];
}
