import styled, { keyframes } from "styled-components";
import { formatDateToDisplay } from "helpers/date";
import { AllowedDisplayMessageTypes } from "redux/types";

const MessageItem = styled.li<{ isWatson: boolean }>`
  display: grid;
  grid-template-areas:
    "content"
    "sender";
  margin-bottom: 1rem;
  max-width: 70%;
  align-self: ${props => (props.isWatson ? "flex-start" : "flex-end")};
`;

const popUpAnimationRight = keyframes`
    0% { transform: scale(.2) translate(130%, 0); }
    60% { transform: scale(1.1) translate(0); }
    100% { transform: scale(1) translate(0); }
    `;

const popUpAnimationLeft = keyframes`
    0% { transform: scale(.2) translate(-130%, -100%); }
    60% { transform: scale(1.1) translate(0); }
    100% { transform: scale(1) translate(0); }
    `;

const MessageContent = styled.div<{ isWatson: boolean }>`
  grid-area: content;
  justify-self: ${props => (props.isWatson ? "flex-start" : "flex-end")};
  padding: 0.5rem 0.6rem;
  background: ${props =>
    props.isWatson
      ? props.theme.assistant.color_message_bot_bg
      : props.theme.assistant.color_message_bg};
  color: ${props => props.theme.assistant.color_message};
  margin-bottom: 0.2rem;
  will-change: transform;
  border-radius: ${props =>
    props.isWatson ? "0 10px 10px 10px" : "10px 10px 0 10px"};
  animation: ${props =>
      props.isWatson ? popUpAnimationLeft : popUpAnimationRight}
    600ms 1 ease;
`;

const MessageSender = styled.span<{ isWatson: boolean }>`
  grid-area: sender;
  width: max-content;
  color: ${props => props.theme.assistant.color_message};
  font-size: 0.8rem;
  justify-self: ${props => (props.isWatson ? "flex-start" : "flex-end")};
`;

interface Props {
  message: AllowedDisplayMessageTypes;
  children: React.ReactNode;
}

const GenericMessage = ({ message, children }: Props): JSX.Element => {
  const isWatson = message.sender === "Rafa";

  return (
    <MessageItem key={message.id} isWatson={isWatson}>
      <MessageContent isWatson={isWatson}>{children}</MessageContent>
      <MessageSender isWatson={isWatson}>
        <strong>{message.sender}</strong>{" "}
        {formatDateToDisplay(new Date(message.timestamp))}
      </MessageSender>
    </MessageItem>
  );
};

export default GenericMessage;
