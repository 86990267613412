import { ChatState, ChatActionTypes } from "./types";
import {
  MESSAGE_ADD,
  MESSAGE_CLEAR,
  CONTEXT_CHANGE,
  MESSAGE_HEAP_ADD,
  MESSAGE_HEAP_POP,
  MESSAGE_HEAP_CLEAR,
} from "./actions";
import splice from "helpers/splice";

const initialState: ChatState = {
  messages: [],
  messageHeap: [],
  context: undefined,
};

/**
 * Função pura, que recebe o state atual e uma ação, e retorna um novo state.
 * @param state - O state atual do App.
 * @param action - Uma ação a ser executada no state atual.
 * @returns O novo state.
 */
export function chatReducer(
  state = initialState,
  action: ChatActionTypes
): ChatState {
  switch (action.type) {
    case MESSAGE_ADD:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case MESSAGE_CLEAR:
      return {
        ...state,
        messages: [],
      };
    case MESSAGE_HEAP_ADD:
      return {
        ...state,
        messageHeap: [...state.messageHeap, ...action.payload],
      };
    case MESSAGE_HEAP_POP:
      return {
        ...state,
        messageHeap: splice(state.messageHeap, state.messageHeap.length - 1, 1),
      };
    case MESSAGE_HEAP_CLEAR:
      return {
        ...state,
        messageHeap: [],
      };
    case CONTEXT_CHANGE:
      return {
        ...state,
        context: action.payload,
      };
    default:
      return state;
  }
}
